<div mat-dialog-title>
    <div class="justify-between">
        <h1 class="fw-500">
            Violation Letter Preview
        </h1>
        <app-button-save-exit/>
    </div>
</div>

<div mat-dialog-content>
<!--    <div class="mt-24">-->
<!--        <ngx-extended-pdf-viewer *ngIf="selectedFile"-->
<!--                                 [src]="selectedFile"-->
<!--                                 [zoom]="'auto'"-->
<!--                                 [height]="'70vh'"-->
<!--                                 [showToolbar]="true"-->
<!--                                 [showSidebarButton]="false"-->
<!--                                 [showFindButton]="true"-->
<!--                                 [showPagingButtons]="true"-->
<!--                                 [showZoomButtons]="true"-->
<!--                                 [showOpenFileButton]="false"-->
<!--                                 [showPrintButton]="true"-->
<!--                                 [showRotateButton]="false"-->
<!--                                 [showHandToolButton]="false"-->
<!--                                 [showScrollingButton]="false"-->
<!--                                 [showSpreadButton]="false"-->
<!--                                 [showPropertiesButton]="false"-->
<!--                                 [showTextEditor]="false"-->
<!--                                 [showDrawEditor]="false"-->
<!--                                 [showStampEditor]="false"-->
<!--                                 [showDownloadButton]="false" />-->
        <iframe *ngIf="selectedFile"
                [src]="selectedFile"
                width="100%"
                height="700px"
                style="border: none;"
        ></iframe>
<!--    </div>-->
</div>

<mat-dialog-actions class="mat-dialog-roam-action pr-28">
    <button mat-button mat-dialog-close class="btn-base tc-black">
        <span class="text-underline">
          Back
        </span>
    </button>
    <div class="d-flex gap-16">
        <app-roam-button label="Save & New" />
    </div>
</mat-dialog-actions>
