import { inject, Injectable, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ViolationService } from "@app/shared/services/violation.service";
import { BehaviorSubject } from "rxjs";
import { Letter } from "@app/shared/interfaces/letter.interface";

@Injectable({ providedIn: "root" })
export class ViolationParagraphData {

  paragraphs: BehaviorSubject<Letter[]> = new BehaviorSubject<Letter[]>([]);
  #violationSrv = inject(ViolationService)
  letter$ = toSignal(this.#violationSrv.getParagraphs(), {initialValue: []});
  labelName = signal<string>('');
  selectedValue = signal<Letter[]>([]);
  violationOptions = signal<any>([]);
}
