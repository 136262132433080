<div class="roam-mdc-progress-bar-wrapper">
  @if (loaders.formSubmitting()) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>

<div mat-dialog-title>
    <div class="justify-between">
        <h1 class="fw-500">
          {{ title }}
        </h1>
        <app-button-save-exit (onClick)="save(true)" />
    </div>
</div>

<div mat-dialog-content class="mt-6"
     [formGroup]="form">
    <div class="d-flex gap-24 f-column mn-24">
        <div>
           <div class="set-grid set-grid-2 stretch pt-6">
               <app-roam-select placeholder="Association"
                                [items]="opts.properties()"
                                formControlName="propertyId"
                                [loading]="loaders.propertiesLoading()"
                                [searchable]="true"
               />
               <app-roam-select placeholder="Address"
                                formControlName="unitId"
                                [loading]="loaders.unitsLoading()"
                                [items]="opts.units()"
                                [searchable]="true"
               />
           </div>
           <div class="set-grid set-grid-1 pt-24">
               <app-roam-datepicker name="Inspection Date"
                                    formControlName="inspectionDate"
               ></app-roam-datepicker>
           </div>
        </div>
        <div>
            <h6 class="title">Select a Category</h6>
            <div class="violation-card mt-16">
                @if (!loaders.typesLoading()) {
                    <app-roam-radio-card 
                        [options]="opts.categories()"
                        formControlName="categoryId"
                    />
                } @else {
                <ng-container *ngTemplateOutlet="spinner"></ng-container>
                }
                
            </div>

            <div class="set-grid set-grid-2 stretch pt-24">
                <letter-dropdown 
                    formControlName="inspectionIssues"              
                    [options]="opts.issues()"
                />
                <div></div>
            </div>

            <div class="set-grid set-grid-2 stretch pt-24">
                <app-roam-select placeholder="Letter"
                                 formControlName="letterTemplateId"
                                 [items]="opts.letterTemplates()"
                />
                <app-roam-select placeholder="Status"
                                 formControlName="status"
                                 [items]="opts.statuses()"
                />
            </div>

            <div class="pt-24">
                <app-roam-text-area name="Additional Information"
                                    formControlName="text"
                ></app-roam-text-area>
            </div>
        </div>
        <div>
            <h6 class="title">Attachments</h6>
            <div>
                <app-file-uploader containerText="Drag Here"
                                   [(data)]="attachments"
                                   [config]="modelConfig()"
                />
            </div>
        </div>

        <app-roam-toggle-slider label="Issue Fine"
                                name="issuefine"
                                formControlName="isIssueFine"
        />
        @if(form.get('isIssueFine')?.value) {
            <div>
                <div class="d-flex gap-16 mb-24">
                    <div class="w-due-date">
                        <app-roam-datepicker name="Due Date"
                                            formControlName="fineDueDate"
                        ></app-roam-datepicker>
                    </div>
                    <app-roam-input class="w-50"
                                    name="Amount"
                                    [currency]="true"
                                    formControlName="fineAmount"
                    ></app-roam-input>
                </div>
                <app-roam-text-area name="Memo"
                                    formControlName="fineMemo"
                ></app-roam-text-area>
            </div>

            <hr class="m-0">
        }
        <app-roam-toggle-slider label="Set Deadline"
                                name="deadline"
                                formControlName="isDeadline"/>

        @if(form.get('isDeadline')?.value) {

            <div class="set-grid set-grid-1">
                <app-roam-datepicker name="Deadline"
                                     formControlName="deadlineDate"
                />
            </div>
            <hr class="m-0">
        }
    </div>

    <div class="sharing mt-16">
        <h6 class="title">Sharing</h6>
        <sharing-email />
    </div>
</div>

<mat-dialog-actions class="mat-dialog-roam-action pr-28">
    <button-cancel />
    <div class="d-flex gap-16">
        <app-button type="btn-o-black"
                    label="Preview"
                    (onClick)="previewInspection()"
        />
        <app-button label="Save" (onClick)="save()"/>
    </div>
</mat-dialog-actions> 


<ng-template #spinner>
    <div class="justify-center mtb-16">
      <mat-spinner diameter="50" />
    </div>
</ng-template>
  
