
<div class="pr-1">
  <input-dropdown
          cdkOverlayOrigin
          #openLetter="cdkOverlayOrigin"
          placeholder="Select Issues"
          [floatingLabel]="false"
          [ngModel]="selectedLabel"
          (click)="toggleDropdown();$event.stopPropagation()"
  />
</div>
<ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayHasBackdrop
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        [cdkConnectedOverlayOrigin]="openLetter"
        [cdkConnectedOverlayOpen]="isDropdown"
        cdkConnectedOverlayPush="true"
        [cdkConnectedOverlayWidth]="320"
        [cdkConnectedOverlayPositions]="dropdownPosition"
        [cdkConnectedOverlayViewportMargin]="7"
        (backdropClick)="closeDropdown()"
>
  <div class="dropdown pl-16 pr-16 pt-8 pb-0">
    <div class="pt-8 pb-16">
      <app-input-search-one (onSearch)="searchLetters($event)"/>
    </div>

    @if (filtered()) {
      <div class="gutter scroll-container pb-16">
      <div class="reverse hovered ">
        <mat-checkbox
                *ngFor="let option of filtered()"
                [checked]="option.selected"
                [(ngModel)]="option.selected"
                (change)="toggleSelection(option)">
          <div class="mat-name fw-500">
            {{ option.name }}
          </div>
        </mat-checkbox>
      </div>
    </div>
    } @else {
      <div class="pb-16">
        <app-empty-state [category]="'house'"/>
      </div>
    }
  </div>
</ng-template>