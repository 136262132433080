import { Component, Inject, Input, input } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { ButtonSaveExitComponent } from "@app/shared/components/button/button-save-exit/button-save-exit.component";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { AssociationService } from '@shared/services/association.service'
import { ViolationService } from '@shared/services/violation.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { NgIf } from '@angular/common'

@Component({
  selector: "app-inspection-preview-letter",
  standalone: true,
  imports: [
    MatDialogModule,
    ButtonSaveExitComponent,
    NgxExtendedPdfViewerModule,
    RoamButtonComponent,
    NgIf,
  ],
  templateUrl: "./inspection-preview-letter.html",
  styles: `
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `,
})
export class InspectionPreviewLetterComponent {
  selectedFile: SafeResourceUrl | undefined;

  constructor(
    private sanitizer: DomSanitizer,
    private violationService: ViolationService,
    @Inject(MAT_DIALOG_DATA) public data: any
) {}

  ngOnInit() {
    this.submit()
  }
  submit() {
    this.violationService.previewViolationLetter(this.data).subscribe(
      (res) => {
        console.log(res);
        const buffer = new Uint8Array(res);
        const file = new Blob([buffer], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.selectedFile = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

}
