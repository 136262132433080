import { IIdName } from "@app/shared/interfaces/id-name.interface";

export const ComplianceStatus: IIdName[] = [
  {
    id: 'NONE',
    name: 'None'
  },
  {
    id: 'IGNORE',
    name: 'Ignore'
  },
  {
    id: 'NEW',
    name: 'New'
  },
  {
    id: 'RESOLVE',
    name: 'Resolve'
  },
  {
    id: 'ESCALATE',
    name: 'Escalate'
  }
]

export const ComplianceStatuses: IIdName[] = [
  {
    id: 1,
    name: "Open"
  },
  {
    id: 2,
    name: "New"
  },
  {
    id: 7,
    name: "Ignore",
  },
  {
    id: 3,
    name: "Resolve"
  },
  {
    id: 2,
    name: "Escalate"
  },
];
