import { Component, computed, EventEmitter, forwardRef, inject, input, model, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDropdownComponent } from "@app/shared/components/input/input-dropdown/input-dropdown.component";
import { ConnectionPositionPair, OverlayModule } from "@angular/cdk/overlay";
import { dropdownPosition } from '@app/core/const/overlay.const';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputSearchOneComponent } from "@app/shared/components/input/input-search-one/input-search-one.component";
import { MatTabsModule } from "@angular/material/tabs";
import { EmptyStateComponent } from "@app/shared/components/empty-state/empty-state.component";
import { ViolationParagraphData } from "@app/pages/reporting/shared/data-access/violation-paragraph-data.ts";
import { Letter } from "@app/shared/interfaces/letter.interface";

@Component({
  standalone: true,
  imports: [
    CommonModule, InputDropdownComponent, OverlayModule,
    FormsModule, InputSearchOneComponent,
    MatSelectModule, MatCheckboxModule, MatRadioModule, MatTabsModule, EmptyStateComponent],
  selector: 'letter-dropdown',
  templateUrl: './compliance-inspection-letter-dropdown.component.html',
  styleUrls: ['./compliance-inspection-letter-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComplianceInspectionLetterDropdownComponent),
      multi: true
    }
  ]
})
export class ComplianceInspectionLetterDropdownComponent implements ControlValueAccessor {

  @Output() change = new EventEmitter<any[]>();

  getId = input<boolean>(false);
  violationParagraphData = inject(ViolationParagraphData)
  dropdownPosition: ConnectionPositionPair[] = dropdownPosition;
  options = model<Letter[]>([]) 
  selectedValue: any[] = [];
  selectedLabel: string = '';
  isDropdown = false;
  filteredOptions: any[] = [];

  filtered = computed(() =>  this.options().map(option => ({...option})));

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    
    if (value) {
      this.selectedValue = value;
      this.updateSelectedLabel()
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDropdown() {
    this.isDropdown = !this.isDropdown;
    this.updateSelectedLabel();
  }

  closeDropdown() {
    this.isDropdown = false;
    this.searchLetters('');
  }

  searchLetters(query: string) {
    if(query) {
      const filtered = this.options()!.filter(option =>
        option.name.toLowerCase().includes(query.toLowerCase())
      );
      this.options.set(filtered);
    } else {
      this.options.set(this.violationParagraphData.violationOptions());
    }
  }

  toggleSelection(option: any) {
    const index = this.selectedValue.findIndex(id => id === option.id)
    if (index > -1) {
      this.selectedValue.splice(index, 1);
    } else {
      this.selectedValue.push(option.id);
    }
    this.updateSelectedLabel();
    this.onChange(this.selectedValue);
    this.change.emit(this.selectedValue);
    
  }

  isSelected(option: any) {
    return this.selectedValue.some(val => val.id === option.id);
  }

  updateSelectedLabel() {
    const valueOptions = this.options().filter(option => this.selectedValue.includes(option.id));
    this.selectedLabel = valueOptions.map(option => option.name).join(',');

    this.markSelectedInFilteredOptions(this.selectedValue)
  }

  updateLabel(): void {
    this.selectedValue = this.options().filter(item => item.selected);
    this.updateSelectedLabel();
  }

  markSelectedInFilteredOptions(violations: string[]): void {
    const checked = this.options().map(option => {
      return {
        ...option,
      selected: violations.includes(option.id)
      }
    })

    this.options.set(checked) 
  }
}
